:root {
    --input-color:#c0e694;
    --moome-color:#80cc28;
   /* --clear-blue:#316a90;
    --dark-blue:#204157;
    --clear-red:#f44336;
    --moome-font:"Exo 2";
    --dark-dividers:rgba($dark-blue: 0.12);
    --light-grey:#f2f2f2;
    --light:#ffffff;
    --page-color:#f6f7fb;
    --backgroud-input:#fcfcfd;
    --dark-primary-text:rgba($dark-blue: 0.87);
    --dark-secondary-text:rgba($dark-blue: 0.54);
    --dark-disabled-text:rgba($dark-blue: 0.38);
    --dark-focused:rgba($dark-blue: 0.12);
    --light-primary-text:white;
    --light-secondary-text:rgba(white: 0.7);
    --light-disabled-text:rgba(white: 0.5);
    --light-dividers:rgba(white: 0.12);
    --light-focused:rgba(white: 0.12);*/
    --50: #f0f9e5;
    --100: #d9f0bf;
    --300: #a6db69;
    --400: #93d448;
    --600: #78c724;
    --700: #6dc01e;
    --800: #63b918;
    --900: #50ad0f;
    --A100: #e9ffdc;
    --A200: #c9ffa9;
    --A400: #a9ff76;
    --A700: #99ff5d;
  }
