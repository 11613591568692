/* You can add global styles to this file, and also import other style files */
@import "../../shared/style/material-style";

html,
body {
  font-family: "Exo 2", "sans-serif" !important;
  background-color: $page-color;
  height: 100%;
  width: 100%;
  margin: 0px !important;
  padding: 0px !important;
  left: 0px !important;
  top: 0px !important;
  overflow-x: hidden;
  overflow-y: auto !important;
}
.pointer {
  cursor: pointer;
}
.color-male {
  color: #6282d9;
}
.color-femelle {
  color: #e68be3;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $moome-color;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: $moome-color;
  background-color: $moome-color;
}
.mat-radio-outer-circle {
  border-color: $moome-color;
}

* {
  outline: none;
}

//shared style moome app (public V2)//

.button-mob {
  font-size: 25px;
  font-weight: 500;
  height: 64px;
  border-radius: 12px;
  width: 280px;
}
.button-mob-text {
  font-size: 20px;
  position: relative;
  top: -4px;
}
.button-mob-text-ar {
  font-size: 25px;
}

.button-signin-style {
  background-color: $dark-blue !important;
  color: white;
}

.owl-theme .owl-dots .owl-dot.active span {
  background: $clear-blue !important;
}

.owl-theme .owl-dots .owl-dot span {
  background: #c2c2c2 !important;
  width: 12px;
  height: 12px;
  margin: 5px 7px;
}

.input-mob {
  border: none;
  background-color: $backgroud-input;
  height: 70px;
  font-size: 25px;
  width: 290px;
  box-shadow: 5px 5px 5px#00000029;
  color: $clear-blue ;
  opacity: 0.7;
  padding: 0px 25px;
}

.input-search-mob {
  border: none;
  height: 60px;
  width: 81% !important;
  font-size: 20px;
  color: $dark-blue !important;
  opacity: 0.5;
  padding: 0px 25px;
}

.button-dialog {
  //width: 100px !important;
  min-width: 100px !important;
  line-height: 65px !important;
  height: 55px;
  border: none;
  border-radius: 0px;
}

.moome-style {
  height: 140px;
}

.dialog-size-mob {
  //max-width: 369px;
  max-height: 600px;
}

.op {
  opacity: 1;
}

.mat-select-value{
  height:  50px;
}

.mat-select-arrow {
  border-left: 15px solid transparent !important;
  border-right: none !important;
  border-top: 15px solid transparent !important;
  border-image-source: url("assets/images/Polygone-6.svg") !important;
  border-image-repeat: stretch !important;
  position: relative;
  // top: -2px;
  margin: 0 !important;
}

.mat-select-placeholder {
  line-height: 69px;
  color: $dark-blue !important;
  opacity: 0.7;
}


/*::ng-deep .mat-select-panel .mat-option {
  font-size: 20px !important;
}*/

.text-style-setting {
  font-size: 22px;
  font-weight: bold;
  color: $dark-blue;
}

.color-group-cercle {
  height: 30px;
  width: 30px !important;
  border-radius: 50%;
  box-shadow: -2px 3px#00000029;
}

.title-style-dialog {
  font-size: 30px;
  color: $dark-blue;
}

.back-style-com {
  background-color: $light-grey;
  width: 368px;
  height: 63px;
  box-shadow: 0px 3px 6px#00000029;
}

.fixed-bar-mob {
  position: fixed;
  bottom: 0;
  width: 100%;
  transition: bottom 0.3s ease-in-out; // Transition on hide/show.
}

.center-element {
  display: flex;
  align-items: center;
  justify-content: center;
}

.style-bar {
  height: 91px;
}

.color-dark-blue {
  color: $dark-blue;
}
.moome-color {
  color: $moome-color;
}
.position {
  position: relative;
  top: 110px;
}

.male-style {
  background-color: #6282d9;
}

.female-style {
  background-color: #e68be3;
}

.mat-dialog-container {
  border-radius: 0px !important;
}

.opaci {
  opacity: 0.3;
}

.bor-style {
  border: 5px solid $dark-blue;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.opac {
  opacity: 0.3;
}
.style-bar-troups {
  position: fixed;
  z-index: 900;
  transition: top 0.3s ease-in-out; // Transition on hide/show.
}

.background-troups {
  background-color: $clear-blue;
  height: 90px;
}

.input-mob-date {
  border: none;
  background-color: $backgroud-input;
  height: 50px;
  font-size: 25px;
  width: 290px;
  box-shadow: 5px 5px 5px#00000029;
  color: $clear-blue !important;
  opacity: 0.7;
  --highlight-background:transparent !important;
//-webkit-rtl-ordering: visual;

}

ion-datetime {
  font-family: "Exo 2", "sans-serif";
}

ion-datetime {
  --placeholder-color: dimgrey !important;
}
ion-picker{
  position: fixed !important
}

ion-picker {
  
  .picker-toolbar .sc-ion-picker-ios, .picker-toolbar-button .sc-ion-picker-ios {
    font-size: 25px;
    font-weight: 600;
    color:  $moome-color;
    font-family: "Tajawal";
   }
  .picker-opt {
    color: $dark-blue;
    font-size: 28px !important;
  }
}

ion-picker-column {
  max-width: 125px !important;
  font-family: "Tajawal";
}


.picker-toolbar-cancel{
.sc-ion-picker-ios{
font-weight: 600!important;
color: red !important;
}}

ion-datetime::part(text){
    direction: inherit;
    font-family: "Tajawal";
   
}
.orange{
  color:#F6A001;
}
.green{
  color:$moome-color;}
.red{
  color:#F1493B;
}

.color-thi-moome{
color: #7CC729}
.color-thi-orange{
color:#F6A001}
.color-thi-red{
color:#F1493B}
.opac {
  opacity: 0.3;
}

.background-thi {
  background-color: red;
  height: 90px;
}
.color-blue{
  background-color:#316a90;
}
.color-white{
  background-color: #fff;
}
.color-transparent{
  background-color:transparent;
}
.color-abort{
  background-color: #632289;
}
.color-feed{
  background-color: #F6A001;
}
.color-milk-prod{
  background-color:#06AF8F}
.color-alert{
  background-color: #204157;
}
.color-stock{
  background-color:#444444;}
